import { AuthProvider, UserIdentity } from 'react-admin';
import { abpSessionManager } from './AbpProvider';
import { User, UserManager } from "oidc-client-ts";
import getProfileFromToken from "./getProfileFromToken";
import { mgr as userManager } from "./odicUserManager";
import { abpConfigurationProvider } from '../abp/abpConfigurationProvider';
import { configService } from '../abp/configService';

const issuer = 'https://uat.auth.services.woradelivery.com'; //import.meta.env.VITE_OIDC_ISSUER;
//const clientId = import.meta.env.VITE_OIDC_CLIENT_ID;
const redirectUri = 'http://localhost:3000/#/login'; // import.meta.env.VITE_OIDC_REDIRECT_URI;
const apiUri = 'cccc'; // import.meta.env.VITE_API_URL;
const clientId = 'shopPortalOpenId';

const cleanup = () => {
  // Remove the ?code&state from the URL
  window.history.replaceState(  
    {},
    window.document.title,
    window.location.origin
  );
};

// const userManager = new UserManager({
//   authority: issuer as string,
//   client_id: clientId as string,
//   redirect_uri: redirectUri as string,
//   response_type: "code",
//   scope: "offline_access openid profile email phone AccountService IdentityService AdministrationService SaasService ProductService RoutingService IntegrationService CorbosService", // Allow to retrieve the email and user name later api side
// });

const authProviderWithOpenId : AuthProvider ={
  login: async (params: any) : Promise<void> => {
    return new Promise<void>((resolve) => {
      console.log('login');
      const { code, state } = params;
      
      if (!code && !state) {
        console.log('go to login');
        // 1. Redirect to the issuer to ask authentication
        userManager.signinRedirect().catch((error) => console.error(error));
      } else {
        console.log('login complete');
        // 2. We came back from the issuer with code and state
        try {
          userManager
          .signinCallback()
          .then((user: User | void) => {
            userManager
              .storeUser(user as User)
              .then(() => {
                userManager.clearStaleState();
              })
              .finally(() => resolve());
          })
          .catch((error) => {
            console.error(`login error: ${error}`);
          });
        } catch (error) {
          console.error(`login error: ${error}`);
        }
        
      }
    });
  },
  logout: () => 
    {
      console.log('logout called');
      
    
    localStorage.removeItem("oidc.user:https://uat.auth.services.woradelivery.com:shopPortalOpenId");
    const id_token = localStorage.getItem("token");
    localStorage.removeItem("token");
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      
      if (key && key.includes("oidc")) {  // Aggiunta di un controllo per evitare possibili valori null
        localStorage.removeItem(key);
        i--; // Decrementa l'indice per compensare la rimozione
      }
    }

    userManager.signoutRedirect({post_logout_redirect_uri: configService.get().post_logout_redirect_uri, //"http://localhost:3000/",
      id_token_hint: id_token!}).then(() => {
      console.log('logout success');
      localStorage.removeItem("token");
      });
    return Promise.resolve();
  },
  checkError: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: () => {
    console.log('checkAuth');
    
    return userManager.getUser().then((a : any) => {
      // console.log('user chackauth ->', a);
      if (a == null) {
        // const currentTime = Math.floor(Date.now() / 1000); // Ottieni il tempo corrente in secondi
        // if(a?.expires_at < currentTime){
        //   localStorage.setItem("token", a?.access_token!);
        //   userManager.clearStaleState();
        //   return Promise.resolve();
        // }
        console.log('user need to login');
        userManager.signinRedirect();
        return;
      }else{
        console.log('user logged in');
        localStorage.setItem("token", a?.access_token!);
        userManager.clearStaleState();
        return Promise.resolve();
      }
      
      
    });

    // return Promise.resolve();
    // console.log('checkAuth');
    // const token = localStorage.getItem("token");

    // if (!token) {
    //   return Promise.reject();
    // }

    // // This is specific to the Google authentication implementation
    // const jwt = getProfileFromToken(token);
    // const now = new Date();

    // return now.getTime() > jwt?.exp! * 1000
    //   ? Promise.reject()
    //   : Promise.resolve();
  },
  getPermissions: () => { 
    const permissions = abpConfigurationProvider.getPermission();
    return Promise.resolve(permissions);
    },
  getIdentity: () => {
    console.log('getIdentity');
    const token = window.localStorage.getItem("token");
    const profile : any = getProfileFromToken(token);

    return Promise.resolve({
      id: profile?.sub ?? '',
      fullName:  profile?.family_name +' ' + profile?.given_name, //profile?.name,
      avatar: '', //profile?.picture,
    });
  },
  handleCallback: async () => {
    console.log('handleCallback');
    const query = window.location.search;
    
    // We came back from the issuer with ?code infos in query params
    const { searchParams } = new URL(window.location.href);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    // oidc-client uses localStorage to keep a temporary state
    // between the two redirections. But since we need to send it to the API
    // we have to retrieve it manually
    const stateKey = `oidc.${state}`;
    const { code_verifier } = JSON.parse(
      localStorage.getItem(stateKey) || "{}"
    );

    // Transform the code to a token via the API
    const response = await fetch(`${apiUri}/code-to-token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code: code, code_verifier }),
    });

    if (!response.ok) {
      cleanup();
      return Promise.reject();
    }

    const token = await response.json();

    localStorage.setItem("token", JSON.stringify(token));
    userManager.clearStaleState();
    cleanup();
    return Promise.resolve();
  },
};

export default authProviderWithOpenId;