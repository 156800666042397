import React, { useEffect, useState } from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import {
    Create,
    FormDataConsumer,
    Notification,
    SaveButton,
    SimpleForm,
    Toolbar,
    useNotify,
    useTranslate,
    useGetIdentity, ReferenceInput, required, AutocompleteInput, usePermissions, useRedirect
} from "react-admin";
import { Accordion, AccordionDetails, AccordionSummary, HtmlTooltip, useStyles } from "../style/Index";
import { massCreate } from '../utils/lib';
import {
    DELIVERY_PANEL_NAME, LARGE_PACKAGE_INFO,
    MEDIUM_PACKAGE_INFO,
    SHOP_TO_SHOP_NEW_ORDER_FIELDS,
    PICKUP_PANEL_NAME,
    SMALL_PACKAGE_INFO,
    staticProductList,
    FIELD_DESCRIBE,
    StepDefinition,
    FormFieldDefinition,
    DeliveryDefinition
} from "../constant";
import Grid from "@material-ui/core/Grid";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FieldInput from "../utils/FieldInput";
import { useOrderStyles } from "./OrderStyle";
import {
    abpPermissionCheck,
    calculatePackageIVAPercentage,
    calculatePackageTotalAmount, formatSearchObject,
    generateInitialStartdate,
    getDataFromLocalDb, innerStringWhiteSpaceRemoval, permissionCheck
} from "../utils/Helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from "@material-ui/icons/Info";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { doQuery } from "../utils/lib";
import { TABLE_AUTH, TABLE_DESCRIBE } from "../utils/local-db";
import ScheduleIcon from "@material-ui/icons/Schedule";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import AddresEdit from "../address/AddresEdit";
import { customAlphabet } from 'nanoid'
import { PaymentInfo } from "../payment/PaymentInfo";
import { format } from "date-fns";
import PhoneInput from "react-phone-input-2";
import {
    ShopDeliveryService, Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto,
    wora_CorbosService_DeliverySteps_StepType, wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto, wora_CorbosService_DeliverySteps_ShopDeliveryStepDto, wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryUpdateDto,
    wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto, wora_CorbosService_DeliverySteps_ShopDeliveryStepCreateDto,
    wora_CorbosService_DeliverySteps_ShopDeliveryStepUpdateDto
} from '../packages/proxy/src';
import { useHistory, useParams } from 'react-router-dom';
import useAbp from '../useAbp';
import { DeliveryCreateOrEdtiDto } from '../types/DeliveryCreateOrEdtiDto';
import { FormDeliveryUpdateOrCreate } from '../types/FormDeliveryUpdateOrCreate';
import { da, de, fi } from 'date-fns/locale';
import { abpConfigurationProvider } from '../abp/abpConfigurationProvider';
import { FieldInputTypeDto } from '../types/FieldInputTypeDto';
import { FieldDisplayUiType } from '../types/FieldDisplayUiType';

const ShopToShopOrder = (props: any) => {
    const classes = useStyles();
    const orderClasses = useOrderStyles();
    const redirect = useRedirect();
    const history = useHistory();
    const notify = useNotify();
    const translate = useTranslate();
    const abp = useAbp();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [expandedPanel, setExpandedPanel] = React.useState('');
    const [showPaymentInfo, setShowPaymentInfo] = useState<boolean>(false);
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [expanded, setExpanded] = React.useState<any>('');
    const [lastExpand, setLastExpanded] = React.useState('');
    const [showAddAddres, setShowAddAddress] = useState<any>({});
    const [fields, setFields] = useState<FieldInputTypeDto[]>([]);
    const [describe, setDescribe] = useState<any>(null);
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const [oPackages, setPackages] = useState<any>([]);
    const [contactDetails, setContactDetails] = useState<any>({});
    const [userAccount, setUserAccount] = useState<any>({});
    const [createdAddressIdPickUp, setCreatedAddressIdPickUp] = useState<string>('');
    const [createdAddressIdDelivery, setCreatedAddressIdDelivery] = useState<string>('');

    const [products, setProducts] = useState<any[]>([]);
    const [extraFields, setExtraFields] = useState<boolean>(false);
    const [massCreateData, setMassCreateData] = useState<any>(null);
    const [editablePhone, setEditablePhone] = useState<string>('');
    const { identity } = useGetIdentity();
    const { permissions } = usePermissions();
    const initialStartDate = generateInitialStartdate();
    const [startDate, setPickupFromDate] = useState<any>(generateInitialStartdate());

    const [record, setRecord] = useState<wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto>({});
    const [deliveryRecord, setDeliveryRecord] = useState<wora_CorbosService_DeliverySteps_ShopDeliveryStepDto>({});
    const [pickupRecord, setPickupRecord] = useState<wora_CorbosService_DeliverySteps_ShopDeliveryStepDto>({});
    const [deliveryDefinition, setDeliveryDefinition] = useState<DeliveryDefinition>({});
    
    const { id } = useParams<{ [key: string]: string }>();

    const [userInput, setUserInput] = useState<DeliveryCreateOrEdtiDto>({});

    useEffect(() => {
        if (typeof permissions !== "undefined") {
            if (!abpPermissionCheck(permissions, 'CorbosService.ShopPortal.ShopToShop')) {
                redirect('/delivery');
            }
        }
    }, [permissions, redirect])
    
    useEffect(() => {
        // console.log('Id ->', id);

        if (id !== undefined) {
            const data = props?.record ?? {} as wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto;
            setRecord(data);
            data.steps?.map((step: wora_CorbosService_DeliverySteps_ShopDeliveryStepDto) => {
                if (step.stepType == wora_CorbosService_DeliverySteps_StepType._0) {
                    // console.log('Pickup Record', step);
                    if (step.requested) {
                        setPickupFromDate(new Date(step.requested ?? ''));
                    }

                    setPickupRecord(step);
                } else if (step.stepType == wora_CorbosService_DeliverySteps_StepType._1) {
                    // console.log('Delivery Record', step);
                    setDeliveryRecord(step);
                }
            });

            
            setUserInput((prevState: any) => {
                // console.log('Data from backend', data);
                return {
                    ...prevState,

                    totLargePackages: data.totLargePackages,
                    totMediumPackages: data.totMediumPackages,
                    totSmallPackages: data.totSmallPackages,
                    

                    storeId: data.storeId,
                    storeName: data.storeName,
                    concurrencyStamp: data.concurrencyStamp,
                }
            });


        } else {
            setUserInput((prevState: any) => {
                // console.log('Data from backend', data);
                return {
                    ...prevState,

                    totLargePackages: 0,
                    totMediumPackages: 0,
                    totSmallPackages: 0,

                }
            });
        }

    }, [id]);

    const validateRecords = async (value: any) => {
        setIsLoading(true);
        console.log('Validate Record', value);
        let data: FormDeliveryUpdateOrCreate = {};

        data.pickupStoreId = value[`store_id-${PICKUP_PANEL_NAME}`];
        data.pickupContact = value[`referente-${PICKUP_PANEL_NAME}`];
        data.pickupEmail = value[`email-${PICKUP_PANEL_NAME}`];
        const mobileInputPickUp: any = document.getElementById(`phone-${PICKUP_PANEL_NAME}`);
        data.pickupPhone = innerStringWhiteSpaceRemoval(mobileInputPickUp?.value ?? '');
        data.pickupNote = value[`notes-${PICKUP_PANEL_NAME}`];
        data.pickupFromDate = format(new Date(startDate ?? initialStartDate), "yyyy-MM-dd HH:mm:ss");

        data.deliveryStoreId = value[`store_id-${DELIVERY_PANEL_NAME}`];
        data.deliveryContact = value[`referente-${DELIVERY_PANEL_NAME}`];
        data.deliveryEmail = value[`email-${DELIVERY_PANEL_NAME}`];
        const mobileInputDelivery: any = document.getElementById(`phone-${DELIVERY_PANEL_NAME}`);
        data.deliveryPhone = innerStringWhiteSpaceRemoval(mobileInputDelivery?.value ?? '');
        data.deliveryNote = value[`notes-${DELIVERY_PANEL_NAME}`];

        // Global       
        data.paymentStatus = 0; // Not Paid      
        data.tot_large_packages = oPackages.filter((pack: any) => pack.name === 'tot_large_packages')[0]?.count;
        data.tot_small_packages = oPackages.filter((pack: any) => pack.name === 'tot_small_packages')[0]?.count;
        data.tot_medium_packages = oPackages.filter((pack: any) => pack.name === 'tot_medium_packages')[0]?.count;
        let hasError: boolean = false;
        let message: string = '';
        
        if (!data.pickupPhone || !data.deliveryPhone) {
            hasError = true;
            message = message + '\n' + translate('CorbosService::Phone') + ' : ' + translate('ra.validation.required');
        } else if (data.pickupPhone.length < 5 || data.deliveryPhone.length < 5) {
            hasError = true;
            message = message + '\n' + translate('CorbosService::Phone') + ' : ' + translate('ra.validation.required');
        }
        if (data.tot_large_packages === 0 && data.tot_medium_packages === 0 && data.tot_small_packages === 0) {
            hasError = true
            message = message + '\n' + translate('CorbosService::SelectAtLeastOnePackage');
        }

        if (hasError) {
            setTimeout(() => {
                notify(message, 'error');
                setIsLoading(false);
            }, 1000);
        } else {
                        
            const paymentInfo: any = {
                subtotal: 0,
                ivaPercentage: 0,
                ivaValue: 0,
                total: 0
            };

            // if (expandedPanel === DELIVERY_PANEL_NAME) {
            //     oPackages.forEach((oPackage: any) => {
            //         values[oPackage.name] = oPackage.count;
            //         let packageSize = '';
            //         if (oPackage.name === 'tot_small_packages') {
            //             packageSize = 'Small';
            //         }
            //         if (oPackage.name === 'tot_medium_packages') {
            //             packageSize = 'Medium';
            //         }
            //         if (oPackage.name === 'tot_large_packages') {
            //             packageSize = 'Large';
            //         }

            //         paymentInfo.subtotal = paymentInfo.subtotal + calculatePackageTotalAmount(oPackage, packageSize, products);
            //         paymentInfo.ivaPercentage = calculatePackageIVAPercentage(packageSize, products);
            //     });
            // }
            // paymentInfo.ivaValue = paymentInfo.subtotal * paymentInfo.ivaPercentage / 100;
            // paymentInfo.total = paymentInfo.ivaValue + paymentInfo.subtotal;
            // values['payment_info_total'] = paymentInfo.total;
            //TODO skip payment info
            // setPaymentInfo(paymentInfo)

            saveRecord(data)
        }
        

    };

    const saveRecord = async (value: FormDeliveryUpdateOrCreate) => {
        setIsLoading(true);

        try {

        
        // let input: wora_CorbosService_Deliveries_DeliveryCreateShopPortal = {};


        // let timeFrom = value['time_from-Delivery'];;
        // console.log('Time From', timeFrom);

        if (id != undefined) { // Update

            let input: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryUpdateDto = {}
            
            input.concurrencyStamp = record.concurrencyStamp; // Concurrency Stamp

            input.storeId = value.pickupStoreId;
            input.totLargePackages = value?.tot_large_packages || 0;
            input.totMediumPackages = value?.tot_medium_packages || 0;
            input.totSmallPackages = value?.tot_small_packages || 0;
            // input.orderDate = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
            input.steps = [];

            // Pickup Data
            let pickupStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepUpdateDto = {};
            // pickupStep.stepType = wora_CorbosService_DeliverySteps_StepType._0;
            pickupStep.storeId = value.pickupStoreId;
            pickupStep.contactName = value.pickupContact;
            pickupStep.contactEmail = value.pickupEmail;
            pickupStep.contactPhone = value.pickupPhone;
            pickupStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
            pickupStep.note = value.pickupNote;
            
            // switch (value.deliveryType) {
            //     case 1: // Wora Same Day
            //     case 2: // Wora Flash
            //         pickupStep.requested = value.startDate ? new Date(value.startDate).toISOString() : null;
            //         break;
            //     case 3: // Wora Scheduled   
            //         pickupStep.requested = value.scheduledDate ? new Date(value.scheduledDate).toISOString() : null;
            //         break;
            // }
            pickupStep.id = pickupRecord.id;

            input.steps.push(pickupStep);

            // Delivery Data
            let deliveryStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepUpdateDto = {};
            // deliveryStep.stepType = wora_CorbosService_DeliverySteps_StepType._1;

            deliveryStep.storeId = value.deliveryStoreId;
            deliveryStep.contactName = value.deliveryContact;
            deliveryStep.contactEmail = value.deliveryEmail;
            deliveryStep.contactPhone = value.deliveryPhone;
            deliveryStep.note = value.deliveryNote;
            deliveryStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
            deliveryStep.id = deliveryRecord.id; // Delivery Step Id

            input.steps.push(deliveryStep);

            // console.log('Create Update',input);
            await ShopDeliveryService.putApiShopDeliveries(id, input).then(data => {
                setIsLoading(false);
                history.push('/delivery'); // Go to List
            }).catch(err => {
                setTimeout(() => {
                    notify(translate('CorbosService::SavingError'), 'error');
                    setIsLoading(false);
                }, 1000);
                setIsLoading(false);
            });
        } else {
            let input: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto = {};

            input.orderType = 2; // Shop to Shop Order
            input.storeId = value.pickupStoreId;
            input.totLargePackages = value?.tot_large_packages || 0;
            input.totMediumPackages = value?.tot_medium_packages || 0;
            input.totSmallPackages = value?.tot_small_packages || 0;
            // input.externalOrderCode = value.retailerid;

            // input.orderDate = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;

            input.steps = [];

            // Pickup Data
            let pickupStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepCreateDto = {};
            pickupStep.stepType = wora_CorbosService_DeliverySteps_StepType._0; // Pickup
            pickupStep.storeId = value.pickupStoreId;
            pickupStep.contactName = value.pickupContact;
            pickupStep.contactEmail = value.pickupEmail;
            pickupStep.contactPhone = value.pickupPhone;
            pickupStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
            pickupStep.note = value.pickupNote;
            
            input.steps.push(pickupStep);

            // Delivery Data
            let deliveryStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepCreateDto = {};
            deliveryStep.stepType = wora_CorbosService_DeliverySteps_StepType._1; // Delivery
            deliveryStep.storeId = value.deliveryStoreId;
            deliveryStep.contactName = value.deliveryContact;
            deliveryStep.contactEmail = value.deliveryEmail;
            deliveryStep.contactPhone = value.deliveryPhone;
            deliveryStep.note = value.deliveryNote;
            deliveryStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    
            // switch (value.deliveryType) {
            //     case 1: // Wora Same Day
            //     case 2: // Wora Flash
            //         deliveryStep.timeFrom = value.startDate ? new Date(value.startDate).toISOString() : null;
            //         break;
            //     case 3: // Wora Scheduled   
            //         deliveryStep.timeFrom = value.scheduledDate ? new Date(value.scheduledDate).toISOString() : null;
            //         break;
            // }

            input.steps.push(deliveryStep);

            // console.log('Create Mode',input);
            //TODO disable when payment info
            await ShopDeliveryService.postApiShopDeliveriesCreate(input).then(data => {
                setIsLoading(false);
                history.push('/delivery'); // Go to List
            }).catch(err => {
                setIsLoading(false);
                setTimeout(() => {
                    notify(translate('CorbosService::SavingError'), 'error');
                    setIsLoading(false);
                }, 1000);
            });
        }

    }catch(err) {
        setIsLoading(false);
        setTimeout(() => {
            notify(translate('CorbosService::SavingError'), 'error');
            setIsLoading(false);
        }, 1000);
    }
    }

    useEffect(() => {
        // console.log('Init New Return Order');
        // Use to design inteface
        // getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {
        let modFields = FIELD_DESCRIBE.shopToShop;
        setFields(FIELD_DESCRIBE.shopToShop);
        //console.log('####################### Ro',FIELD_DESCRIBE.returnOrder);
        setProducts(staticProductList);
        setDeliveryDefinition(SHOP_TO_SHOP_NEW_ORDER_FIELDS); // Set the fields
        let packageList = modFields
            .filter((field: any) => field.name === 'tot_small_packages' || field.name === 'tot_medium_packages' || field.name === 'tot_large_packages')
            .map((field: any, index: number) => {
                let info: string[] = [];
                let unit_price;
                if (field.name === 'tot_small_packages') {
                    info = SMALL_PACKAGE_INFO;
                    unit_price = products[0]?.unit_price;
                }
                if (field.name === 'tot_medium_packages') {
                    info = MEDIUM_PACKAGE_INFO;
                    unit_price = products[1]?.unit_price;
                }
                if (field.name === 'tot_large_packages') {
                    info = LARGE_PACKAGE_INFO;
                    unit_price = products[2]?.unit_price;
                }
                const oPackage: any = oPackages.filter((pack: any) => pack.name === field.name)
                //if the package has count then it will not be zero when the other panel is expanded
                if (oPackage.length > 0) {
                    if (oPackage[0]?.count && oPackage[0]?.count > 0) return {
                        name: field.name,
                        label: field.label,
                        count: 0,
                        info: info,
                        index: index,
                        unitPrice: unit_price
                    }
                }
                return { name: field.name, label: field.label, count: 0, info: info, index: index, unitPrice: unit_price }
        });

        setPackages(packageList);
        // })
    }, []) // eslint-disable-line


    useEffect(() => {
        // console.log('ShopToShop - idenitty');
        const userdata = abpConfigurationProvider.getUser();
        const contactDetail = identity && identity.contactDetail;
        if (userdata && id === undefined) {

            pickupRecord.contactName = userdata.name;
            pickupRecord.contactEmail = userdata.email;
            pickupRecord.contactPhone = userdata.phoneNumber;


        }
    }, [abp])


    useEffect(() => {
        // Use to design inteface
        
    }, [expandedPanel]) // eslint-disable-line
    
    
    
    const CreateToolbar = (props: any) => {
        return (
            <Toolbar {...props} className={orderClasses.actionToolbar}
                style={{ backgroundColor: '#fff', padding: '0', position: 'relative' }}>
                <SaveButton
                    label={(id == undefined ? translate('CorbosService::GoToPayment') : translate('CorbosService::Save'))}
                    icon={isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary'} size={24} /> : <></>}
                    //submitOnEnter={true}
                    disabled={isLoading}
                    redirect={false}
                    fullWidth
                    size="small"
                    className={classes.toolbarBtn}
                    {...props}
                />
            </Toolbar>
        );
    }
    const handleChange = (panel: any) => (event: any, newExpanded: any) => {

        const mobileInputPickUp: any = document.getElementById(`phone-${PICKUP_PANEL_NAME}`);
        const mobileInputDelivery: any = document.getElementById(`phone-${DELIVERY_PANEL_NAME}`);

        pickupRecord.contactPhone = innerStringWhiteSpaceRemoval(mobileInputPickUp?.value ?? '');
        deliveryRecord.contactPhone = innerStringWhiteSpaceRemoval(mobileInputDelivery?.value ?? '');

        setExpandedPanel(panel);
        setExpanded(newExpanded ? panel : false);
    };

    const displayPackageBox = (packageName: any, itemNumber: number = 0) => {
        // console.log('displayPackageBox -> ', packageName, itemNumber);

        let packageInfo = oPackages.filter((opackage: any) => opackage.name === packageName)[0];
        packageInfo = packageInfo ?? {};
        packageInfo.count = itemNumber;
        return (
            <Box py={1} px={0.2} mt={1} mb={4} className={orderClasses.packageBox}>
                <Typography component="div" align="center">
                    <Box fontSize="small" justifyContent="center">
                        <Box>
                            <span className={orderClasses.packageTitleBox}>{translate(packageInfo?.label)} </span>
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        {
                                            packageInfo?.info?.map((info: string, index: number) => {
                                                return (
                                                    <Box key={index}> - <span>{`${info}`}</span> </Box>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                }

                            >
                                <InfoIcon className={orderClasses.packageBoxInfoIcon} />
                            </HtmlTooltip>
                        </Box>
                    </Box>
                </Typography>
                <Typography component="div">
                    <Box mt={1} display="flex" justifyContent="center">
                        <Typography className={orderClasses.packageBoxCounterBtn}
                            onClick={() => updatePackageCount(packageInfo?.index, -1)}>
                            <RemoveCircleOutlineIcon /> </Typography>
                        <Typography> <span id={packageInfo.name + '-count'}
                            className={orderClasses.packageBoxCounter}> {packageInfo?.count} </span>
                        </Typography>
                        <Typography className={orderClasses.packageBoxCounterBtn}
                            onClick={() => updatePackageCount(packageInfo?.index, 1)}> <AddCircleOutlineIcon />
                        </Typography>
                    </Box>
                </Typography>
            </Box>
        )
    }

    const handleFormValuesChange = (formDataValues: any) => {
        setTimeout(() => {
            const servizioDiPortineriaBox: any = document.getElementById('servizioDiPortineriaBox');
            if (servizioDiPortineriaBox) {
                if (formDataValues && formDataValues.servizio_di_portineria === 'Si') {
                    servizioDiPortineriaBox.style.display = 'block';
                } else {
                    servizioDiPortineriaBox.style.display = 'none';
                }
            }
            if (formDataValues && formDataValues.modalita_di_consegna === 'Wora Scheduled' &&
                !deliveryDefinition.deliveryInformation?.orderDetails.filter(e => e.name === 'time_from').length && !extraFields) {
                // modalitaDiConsegnaTimeUntilBox.style.display = 'block';
                deliveryDefinition.deliveryInformation?.orderDetails.splice(
                    3, 0,
                    { name: 'time_from', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }
                );
                deliveryDefinition.deliveryInformation?.orderDetails.splice(
                    4, 0,
                    { name: 'time_until', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }
                );
                setExtraFields(true)
            }
            if (formDataValues && formDataValues.modalita_di_consegna !== 'Wora Scheduled') {
                if (deliveryDefinition.deliveryInformation && deliveryDefinition.deliveryInformation?.orderDetails.filter(e => e.name === 'time_from').length > 0 && extraFields) {
                    deliveryDefinition.deliveryInformation?.orderDetails.splice(3, 2);
                    setExtraFields(false);
                }
            }
        }, 1000);
    }

    const updatePackageCount = (indexValue: number, value: number) => {
        // console.log('updatePackageCount', indexValue, value);
        const newPackages = oPackages;
        newPackages[indexValue].count = newPackages[indexValue].count + value;
        newPackages[indexValue].count = newPackages[indexValue].count >= 0 ? newPackages[indexValue].count : 0;

        if (indexValue == 0) {
            setUserInput((prevState: any) => {
                return { ...prevState, totSmallPackages: newPackages[indexValue].count }
            });
        } else if (indexValue == 1) {
            setUserInput((prevState: any) => {
                return { ...prevState, totMediumPackages: newPackages[indexValue].count }
            });
        } else if (indexValue == 2) {
            setUserInput((prevState: any) => {
                return { ...prevState, totLargePackages: newPackages[indexValue].count }
            });
        }

        setPackages([...newPackages]);
    }

    
    return (
        <>
            {showPaymentInfo
                ? <PaymentInfo packages={oPackages} massCreateData={massCreateData}
                    paymentinfo={paymentInfo} {...props} />
                :
                <div className={orderClasses.root}>
                    <Card className={classes.appCard} variant="outlined">
                        <CardContent>
                            <Typography component="div" variant="h6">
                                <Box fontWeight="fontWeightBold" textAlign="center" py="1rem">
                                    {id ? translate('CorbosService::EditDelivery') : translate('CorbosService::CreateDelivery')} {translate('CorbosService::ShopToShop')}
                                </Box>
                            </Typography>

                            <Box py="1rem" mt="-1.6rem">

                                <Create {...props} component="div">
                                    <SimpleForm save={validateRecords} toolbar={<CreateToolbar />}>

                                        <Accordion square expanded={expanded === PICKUP_PANEL_NAME}
                                            onChange={handleChange(PICKUP_PANEL_NAME)} style={{ width: '100%' }}>
                                            <AccordionSummary aria-controls={PICKUP_PANEL_NAME + '-content'}
                                                id={PICKUP_PANEL_NAME + '-header'}>
                                                <Grid container spacing={0} alignItems="center">
                                                    <Grid item xs={2} md={2}>
                                                        <Box mt={0.4}> {expanded === PICKUP_PANEL_NAME ?
                                                            <ExpandLessIcon /> : <ExpandMoreIcon />} </Box>
                                                    </Grid>
                                                    <Grid item xs={8} md={8}>
                                                        <Typography align={'center'}>
                                                            {translate('CorbosService::PickUp')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <div className={classes.accordionContent}>

                                                <AccordionDetails>
                                                    <Box mt="-1rem" textAlign="center">
                                                        <Typography component="div">
                                                            {translate('AbpIdentity::Details')}
                                                        </Typography>
                                                    </Box>
                                                    <Box mt={4}>
                                                        <Grid container spacing={1}>

                                                            {
                                                                deliveryDefinition.pickupInformation?.orderDetails.map((orderInfo: FormFieldDefinition) => {

                                                                    let input: any = null;
                                                                    let key : string = '';

                                                                    // console.log('Order Info -> ', orderInfo);
                                                                    const found = fields.filter((field: any) => field.name === orderInfo.name);
                                                                    // console.log('found S2S -> ', orderInfo.name, found);
                                                                    if (found.length > 0) {
                                                                        // console.log('Found -> ', found);
                                                                        const fieldName = found[0].name;
                                                                        const fieldDefinition = JSON.parse(JSON.stringify(found[0])) as FieldInputTypeDto;
                                                                        fieldDefinition.name = fieldDefinition.name  + `-${PICKUP_PANEL_NAME}`;
                                                                        
                                                                        // console.log('Field Definition -> ', fieldDefinition);
                                                                        // found[0].mandatory = !expanded || expandedPanel === PICKUP_PANEL_NAME;
                                                                        const extraProps: any = {
                                                                            placeholder: fieldDefinition.label,
                                                                            label: fieldDefinition.label,
                                                                            elementId: fieldDefinition.name,
                                                                            prefix: orderInfo.prefix,
                                                                            inputAdornment: orderInfo.inputAdornment ?
                                                                                <orderInfo.inputAdornment /> : <> </>
                                                                        };
                                                                        key = fieldDefinition.name + expanded; 

                                                                        if (fieldName === 'store_id') {
                                                                            // const key = fieldDefinition.name + expanded //change the key to update the values
                                                                            return (
                                                                                <Grid key={key} item
                                                                                    xs={12} md={orderInfo.gridSize}>
                                                                                    <Box mt={-2} display={'inline-flex'}
                                                                                        width={'100%'}>
                                                                                        <ReferenceInput
                                                                                            variant="outlined"
                                                                                            key={fieldDefinition.name}
                                                                                            label={extraProps.placeholder ?? fieldDefinition.label}
                                                                                            placeholder={fieldDefinition.label ?? fieldDefinition.label}
                                                                                            source={fieldDefinition.name}
                                                                                            fullWidth
                                                                                            size={'md'}
                                                                                            reference={'ContactRole'}
                                                                                            alwaysOn={true}
                                                                                            filterToQuery={searchText =>
                                                                                                formatSearchObject('name', searchText)
                                                                                            }
                                                                                            validate={required()}
                                                                                        >
                                                                                            <AutocompleteInput
                                                                                                defaultValue={pickupRecord.storeId}
                                                                                                variant="outlined"
                                                                                                key={'ref' + fieldDefinition.name}
                                                                                                optionText={'name'}
                                                                                            />
                                                                                        </ReferenceInput>
                                                                                        {(expanded && abpPermissionCheck(permissions, 'ContactRole')) &&
                                                                                            (<IconButton
                                                                                                className={orderClasses.addAddressButton}
                                                                                                color={'default'}
                                                                                                size={"small"}
                                                                                                onClick={() => {
                                                                                                    setLastExpanded(expanded)
                                                                                                    setShowAddAddress({ opened: true, panel: PICKUP_PANEL_NAME });
                                                                                                    setExpanded('')
                                                                                                }}>
                                                                                                <Add />
                                                                                            </IconButton>)
                                                                                        }

                                                                                    </Box>
                                                                                </Grid>)
                                                                        }
                                                                        if (fieldName === 'referente') {
                                                                            fieldDefinition.defaultValue = ((pickupRecord?.contactName == undefined) ? undefined : pickupRecord?.contactName);
                                                                            extraProps.label = `${fieldDefinition.label}`;
                                                                        }
                                                                        if (fieldName === 'email') {
                                                                            extraProps.label = `${fieldDefinition.label}`;
                                                                            fieldDefinition.defaultValue = pickupRecord?.contactEmail;
                                                                            // extraProps.onChange = (e: any) => {
                                                                            //     setEmailPickUp(e.target.value)
                                                                            // }
                                                                        }
                                                                        if (fieldName === 'notes') {
                                                                            fieldDefinition.defaultValue = pickupRecord?.note;
                                                                            extraProps.label = `${fieldDefinition.label}`;
                                                                        }
                                                                        if (fieldName === 'phone') {
                                                                            extraProps.elementId = fieldDefinition.name;
                                                                            fieldDefinition.defaultValue = pickupRecord?.contactPhone;
                                                                            
                                                                            input = <Box mt={1.5} mb={4.5}
                                                                                className={'PhoneInputBox'}> {
                                                                                    FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate)
                                                                                } </Box>
                                                                        } else {
                                                                            input = FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate);
                                                                        }
                                                                        
                                                                    }
                                                                    // console.log('Input pickup-> ', input);
                                                                    return (
                                                                        React.Children.toArray(
                                                                            <>
                                                                                {input
                                                                                    && <Grid key={key + 'Grid'} item xs={12}
                                                                                        md={orderInfo.gridSize}> <Box
                                                                                            mt={-2}>{input}</Box> </Grid>
                                                                                }
                                                                            </>
                                                                        )
                                                                    );
                                                                })
                                                            }
                                                        </Grid>
                                                    </Box>
                                                </AccordionDetails>
                                            </div>
                                        </Accordion>

                                        <Accordion square expanded={expanded === DELIVERY_PANEL_NAME}
                                            onChange={handleChange(DELIVERY_PANEL_NAME)} style={{ width: '100%' }}>
                                            <AccordionSummary aria-controls={DELIVERY_PANEL_NAME + '-content'}
                                                id={DELIVERY_PANEL_NAME + '-header'}>
                                                <Grid container spacing={0} alignItems="center">
                                                    <Grid item xs={2} md={2}>
                                                        <Box mt={0.4}> {expanded === DELIVERY_PANEL_NAME ?
                                                            <ExpandLessIcon /> : <ExpandMoreIcon />} </Box>
                                                    </Grid>
                                                    <Grid item xs={8} md={8}>
                                                        <Typography align={'center'}>
                                                            {translate('CorbosService::Delivery')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <div className={classes.accordionContent}>
                                                <AccordionDetails>
                                                    <Box mt="-1rem" textAlign="center">
                                                        <Typography component="div">
                                                            {translate('AbpIdentity::Details')}
                                                        </Typography>
                                                    </Box>

                                                    <Box mt={4}>
                                                        <Grid container spacing={1}>

                                                            {
                                                                deliveryDefinition.deliveryInformation?.orderDetails.map((orderInfo: any) => {

                                                                    let input: any = null;
                                                                    let key : string = '';
                                                                    const found = fields.filter((field: any) => field.name === orderInfo.name);
                                                                    if (found.length > 0) {

                                                                        const fieldName = found[0].name;
                                                                        const fieldDefinition = JSON.parse(JSON.stringify(found[0])) as FieldInputTypeDto;
                                                                        fieldDefinition.name = fieldDefinition.name  + `-${DELIVERY_PANEL_NAME}`;

                                                                                                                                                // found[0].mandatory = !expanded || expandedPanel === DELIVERY_PANEL_NAME;

                                                                        const extraProps = {
                                                                            placeholder: found[0].label,
                                                                            label: '',
                                                                            elementId: found[0].name,
                                                                            prefix: orderInfo.prefix,
                                                                            inputAdornment: orderInfo.inputAdornment ?
                                                                                <orderInfo.inputAdornment /> : <> </>
                                                                        };
                                                                        key = fieldDefinition.name + expanded; 

                                                                        if (fieldName === 'store_id') {
                                                                            // const key = fieldDefinition.name + expanded; //change the key to update the values
                                                                            return (
                                                                                <Grid key={key} style={{ marginTop: '-20px' }} item
                                                                                    xs={12} md={orderInfo.gridSize}>
                                                                                    <Box mt={-2} display={'inline-flex'}
                                                                                        width={'100%'}>
                                                                                        <ReferenceInput
                                                                                            variant="outlined"
                                                                                            key={fieldDefinition.name}
                                                                                            label={extraProps.placeholder ?? fieldDefinition.label}
                                                                                            placeholder={fieldDefinition.label ?? fieldDefinition.label}
                                                                                            source={fieldDefinition.name}
                                                                                            fullWidth
                                                                                            size={'md'}
                                                                                            reference={'ContactRole'}
                                                                                            alwaysOn={true}
                                                                                            filterToQuery={searchText =>
                                                                                                formatSearchObject('name', searchText)
                                                                                            }
                                                                                            validate={required()}
                                                                                        >
                                                                                            <AutocompleteInput
                                                                                                defaultValue={deliveryRecord.storeId}
                                                                                                variant="outlined"
                                                                                                key={'ref' + fieldDefinition.name}
                                                                                                optionText={'name'}
                                                                                            />
                                                                                        </ReferenceInput>
                                                                                        {(expanded && abpPermissionCheck(permissions, 'ContactRole')) &&
                                                                                            (<IconButton
                                                                                                className={orderClasses.addAddressButtonReverse}
                                                                                                color={'default'}
                                                                                                size={"small"}
                                                                                                onClick={() => {
                                                                                                    setLastExpanded(expanded)
                                                                                                    setShowAddAddress({ opened: true, panel: DELIVERY_PANEL_NAME });
                                                                                                    setExpanded('')
                                                                                                }}>
                                                                                                <Add />
                                                                                            </IconButton>)
                                                                                        }

                                                                                    </Box>
                                                                                </Grid>)
                                                                        }
                                                                        if (fieldName === 'referente') {
                                                                            fieldDefinition.defaultValue = deliveryRecord?.contactName;
                                                                        }
                                                                        if (fieldName === 'email') {
                                                                            fieldDefinition.defaultValue = deliveryRecord?.contactEmail;
                                                                        }
                                                                        if (fieldName === 'notes') {
                                                                            fieldDefinition.defaultValue = deliveryRecord?.note;
                                                                            extraProps.label = `${fieldDefinition.label}`;
                                                                        }
                                                                        
                                                                        
                                                                        if (fieldName === 'phone') {
                                                                            extraProps.elementId = fieldDefinition.name;
                                                                            fieldDefinition.defaultValue = deliveryRecord?.contactPhone;
                                                                            
                                                                            input = <Box mt={1.5} mb={4.5}
                                                                                className={'PhoneInputBox'}> {
                                                                                    FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate)
                                                                                } </Box>
                                                                            
                                                                        } else {
                                                                            input = FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate);
                                                                        }

                                                                        
                                                                        

                                                                    }

                                                                    return (
                                                                        React.Children.toArray(
                                                                            <>
                                                                                {input
                                                                                    && <Grid key={key + '-Grid'} item xs={12}
                                                                                        md={orderInfo.gridSize}> <Box
                                                                                            mt={-3}>{input}</Box> </Grid>
                                                                                }
                                                                            </>
                                                                        )
                                                                    );
                                                                })
                                                            }
                                                        </Grid>

                                                        <Box mt={1} textAlign="center">
                                                            <Typography component="div">
                                                                {translate('CorbosService::OtherInfo')}
                                                            </Typography>
                                                        </Box>

                                                        <Box mt={4}>
                                                            <Grid container spacing={1}>

                                                                {
                                                                    deliveryDefinition.deliveryInformation?.furtherInformationDetails?.map((orderInfo: any) => {

                                                                        let input: any = null;
                                                                        let key : string = '';

                                                                        const found = fields.filter((field: any) => field.name === orderInfo.name);
                                                                        // console.log('found S2S other -> ', orderInfo.name, found, fields);
                                                                        if (found.length > 0) {
                                                                            const fieldName = found[0].name;

                                                                            const fieldDefinition = JSON.parse(JSON.stringify(found[0])) as FieldInputTypeDto;
                                                                            fieldDefinition.name = fieldDefinition.name  + `-${DELIVERY_PANEL_NAME}`;

                                                                            const extraProps = {
                                                                                placeholder: found[0].label,
                                                                                label: '',
                                                                                prefix: orderInfo.prefix,
                                                                                classname: '',
                                                                                inputAdornment: orderInfo.inputAdornment ?
                                                                                    <orderInfo.inputAdornment /> : <> </>
                                                                            };
                                                                            key = fieldDefinition.name + expanded; 

                                                                            if(fieldName === 'notes'){
                                                                                fieldDefinition.defaultValue = deliveryRecord?.note;
                                                                            }

                                                                            if (fieldName.startsWith('tot_')) {
                                                                                let defValue = 0;
                                                                                if (fieldName === 'tot_small_packages') {
                                                                                    defValue = userInput.totSmallPackages ?? 0;
                                                                                }
                                                                                else if (fieldName === 'tot_medium_packages') {
                                                                                    defValue = userInput.totMediumPackages ?? 0;
    
                                                                                }
                                                                                else if (fieldName === 'tot_large_packages') {
                                                                                    defValue = userInput.totLargePackages ?? 0;
                                                                                }
                                                                                input = displayPackageBox(orderInfo.name, defValue);
                                                                            } else {
                                                                                const extraProps = {
                                                                                    placeholder: fieldDefinition.label,
                                                                                    classname: '',
                                                                                    label: '',
                                                                                    prefix: orderInfo.prefix,
                                                                                    inputAdornment: orderInfo.inputAdornment ?
                                                                                        <orderInfo.inputAdornment /> : <></>
                                                                                };
                                                                                input = FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate);
                                                                            }

                            
                                                                        }

                                                                        return (
                                                                            React.Children.toArray(
                                                                                <>
                                                                                    {input
                                                                                        && <Grid key={key + 'Grid'} item xs={12}
                                                                                            md={orderInfo.gridSize}>
                                                                                            <Box mt={-3}> {input} </Box>
                                                                                        </Grid>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </AccordionDetails>
                                            </div>
                                        </Accordion>

                                        <FormDataConsumer>
                                            {({ formData }) => {
                                                handleFormValuesChange(formData)
                                                return (<></>);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleForm>
                                </Create>

                                <Notification />
                            </Box>
                        </CardContent>
                    </Card>
                    {showAddAddres?.opened &&
                        (<Box className={'modal'}>
                            <div onClick={() => {
                                setShowAddAddress(false);
                                if (lastExpand === PICKUP_PANEL_NAME) setExpanded(PICKUP_PANEL_NAME)
                                else setExpanded(DELIVERY_PANEL_NAME)
                            }} className="overlay"></div>
                            <Box display={'flex'} flexDirection={'column'} className={'modal-content'} p={7.5}
                                style={{ width: '40%' }}>
                                <AddresEdit {...props} resource={'ContactRole'} shopToShopView={showAddAddres?.panel} togglePopUp={(createdAddress?: string, panelName?: string) => {
                                    setShowAddAddress(false);
                                    setExpanded(lastExpand);
                                    if (createdAddress) panelName === DELIVERY_PANEL_NAME ? setCreatedAddressIdDelivery(createdAddress) : setCreatedAddressIdPickUp(createdAddress);
                                }} />
                            </Box>

                        </Box>)}
                </div>
            }
        </>
    );
};

export default ShopToShopOrder;